export const environment = {
  production: false,
  api: '/api',
  azure: {
    clientId: '3ce9cc0e-5c7b-41b3-ae51-855f384219f2',
    authority:
      'https://login.microsoftonline.com/164d1c6e-ee30-4e58-be44-a58716f390d2',
    redirectUri: 'https://qsm-portal.cenittec.com/',
    clientSecret: 'BJy8Q~2Ueg5ajjcBy4IWAahpcsEMCRjP8g.TWc.M',
    tenantId: '164d1c6e-ee30-4e58-be44-a58716f390d2',
    postLogoutRedirectUri: 'https://qsm-portal.cenittec.com/login',
    scopes: ['user.Read'],
  },
};
